import React, { useState, useEffect } from 'react';
import { Box, Text, Heading } from 'theme-ui';

const Cotizacion = () => {
  const [cotizaciones, setCotizaciones] = useState([]);
  const [fecha, setFecha] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCotizaciones = async () => {
      try {
        const response = await fetch('https://dolarapi.com/v1/dolares');
        if (!response.ok) {
          throw new Error('Error en la respuesta de la API');
        }
        const data = await response.json();
        setCotizaciones(data);

        // Obtener y formatear la fecha actual
        const currentDate = new Date();
        const opciones = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        // console.log('fechaa', currentDate.toLocaleDateString('es-us', opciones))
        // const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
        // setFecha(currentDate.toLocaleDateString('es-us', opciones));
        const fechaEsp = currentDate.toLocaleDateString('es-us', opciones)
        function capitalizarPrimeraLetra(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
        const fechaMayus = capitalizarPrimeraLetra(fechaEsp)
        setFecha(fechaMayus)
        setError(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
      }
    };

    fetchCotizaciones();
  }, []);

  if (error) {
    return null; // No renderizar nada si hay un error
  }

  return (
    <Box sx={styles.container}>
      <Heading as="h1" sx={styles.title}>
        {/* Cotización del dólar hoy  */}
        {fecha}
      </Heading>
      <div style={{ display:'flex' }}>
      {cotizaciones.map(({ nombre, compra, venta }, index) => {
        if (["oficial", "blue", "tarjeta"].includes(nombre.toLowerCase())) {
          return (
            <Box key={index} sx={styles.cotizacion}>
              <Text as="p" sx={styles.nombre}>
                Dólar {nombre}
              </Text>
              <Text as="p" sx={styles.valor}>
                Compra: <span style={{color:'black', fontWeight:'700'}}>{compra}</span> - Venta: <span style={{color:'black', fontWeight:'700'}}>{venta}</span>
              </Text>
            </Box>
          );
        } else {
          return null;
        }
      })}
      </div>
    </Box>
  );
};

const styles = {
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'poppins, sans-serif',
    '@media screen and (max-width: 780px)': { 
        padding: '5px',
      }
  },
  title: {
    fontSize: '16px',
    marginTop: '5px',
    marginBottom: '0px',
    textAlign: 'center',
  },
  cotizacion: {
    backgroundColor: '#ECE9DA',
    padding: '15px',
    marginBottom: '10px',
    margin:'10px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    width:'500px',
    textAlign: 'center',
    '@media screen and (max-width: 780px)': { 
        padding:'5px',
        width:'auto',
      }
  },
  nombre: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#232323',
    backgroundColor: '#BDA1EC',
    borderRadius: '10px',
    '@media screen and (max-width: 780px)': { 
        fontSize: '10px',
        padding:'5px',
        lineHeight: '14px',
      }
  },
  valor: {
    fontSize: '14px',
    fontWeight: '600',
    // color: '#555',
    '@media screen and (max-width: 780px)': { 
        fontSize: '10px',
        padding:'5px',
        lineHeight: '14px',
      }
  },
};

export default Cotizacion;
