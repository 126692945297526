import React, { useEffect, useState } from 'react';

const Criptoticker = () => {
  const [prices, setPrices] = useState([]);
  const [isClient, setIsClient] = useState(false);

  // Asegurar que el componente se renderice solo en el cliente
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient) {
      const fetchPrices = async () => {
        try {
          const symbols = ['BTCUSDT', 'ETHUSDT', 'BNBUSDT', 'ADAUSDT', 'XRPUSDT'];
          const responses = await Promise.all(
            symbols.map(symbol => 
              fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`)
                .then(response => response.json())
            )
          );
          const newPrices = responses.map(response => ({
            symbol: response.symbol,
            price: parseFloat(response.price).toFixed(2),
            imgUrl: getImgUrl(response.symbol),
            name: getName(response.symbol)
          }));
          setPrices(newPrices);
        } catch (error) {
          console.error('Error fetching prices', error);
        }
      };

      fetchPrices();

      const interval = setInterval(fetchPrices, 60000); // Actualizar cada minuto
      return () => clearInterval(interval);
    }
  }, [isClient]);

  const getImgUrl = (symbol) => {
    switch (symbol) {
      case 'BTCUSDT':
        return 'https://coin-images.coingecko.com/coins/images/1/thumb/bitcoin.png';
      case 'ETHUSDT':
        return 'https://coin-images.coingecko.com/coins/images/279/thumb/ethereum.png';
      case 'BNBUSDT':
        return 'https://coin-images.coingecko.com/coins/images/825/thumb/bnb-icon2_2x.png';
      case 'ADAUSDT':
        return 'https://coin-images.coingecko.com/coins/images/975/thumb/cardano.png';
      case 'XRPUSDT':
        return 'https://coin-images.coingecko.com/coins/images/44/thumb/xrp-symbol-white-128.png';
      default:
        return '';
    }
  };

  const getName = (symbol) => {
    switch (symbol) {
      case 'BTCUSDT':
        return 'Bitcoin (BTC)';
      case 'ETHUSDT':
        return 'Ethereum (ETH)';
      case 'BNBUSDT':
        return 'BNB (BNB)';
      case 'ADAUSDT':
        return 'Cardano (ADA)';
      case 'XRPUSDT':
        return 'XRP (XRP)';
      default:
        return '';
    }
  };

  if (!isClient) {
    return null; // No renderizar nada en el servidor
  }

  return (
    <div style={styles.tickerContainer}>
      <div style={styles.tickerContent}>
        {[...prices, ...prices].map((coin, index) => (
          <div style={styles.coin} key={index}>
            <img src={coin.imgUrl} alt={coin.name} style={styles.coinImg} />
            <span>{coin.name}</span> <span><b>USD</b> {coin.price}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Criptoticker;

const styles = {
  tickerContainer: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    background: '#232323',
    color: '#fff',
    fontSize: '0.9rem',
    display: 'flex',
    alignItems: 'center',
  },
  tickerContent: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    animation: 'scroll 30s linear infinite',
  },
  coin: {
    display: 'inline-block',
    padding: '5px 2rem',
  },
  coinImg: {
    height: '25px',
    verticalAlign: 'middle',
    marginRight: '0.5rem',
  },
};

// Insertamos la animación de desplazamiento horizontal solo en el cliente
if (typeof document !== 'undefined') {
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(`
    @keyframes scroll {
      0% { transform: translateX(0); }
      100% { transform: translateX(-50%); }
    }
  `, styleSheet.cssRules.length);
}
